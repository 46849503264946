body {
  font-family: $fontFamilyArial;
  background-color: $color5;
  font-weight: 400;
}
.main-container {
  background-color: transparent;
}

.breadcrumb__link {
  font-weight: 300;
}

.form-control {
  border-radius: 4px;
}
.drop-down {
  border-color: $gColorBorder1;
  border-radius: 4px;

  &__see-more-header {
    border-color: $colorBorder1;
  }
  &__body__item:hover {
    background-color: $color6;
    .product-card__variants__image-variant {
      box-shadow: 0 0 0 1px $color2;
    }
  }
  &-modal .product-card__variants__image-variant {
    border-radius: 4px;
  }
}

.custom-radio {
  .custom-control-input:checked ~ .custom-control-indicator {
    border-color: $color1;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%231d8181'/%3E%3C/svg%3E");
  }
}
