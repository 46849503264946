.design-lampeguru.productcard {
  background-color: $color5;
}

#product-card {
  .product-card {
    &__enlarged {
      .product-card__product-name {
        font-size: initial;
        line-height: initial;
      }
    }
    &__maininfo {
      @media (--mobile) {
        border-color: $colorBorder1;
      }
      .drop-down {
        .label-name {
          font-weight: 100;
        }
        .selected {
          font-weight: 400;
        }
        &:hover,
        &:focus {
          border-color: var(--border-brand-specific);
          .drop-down__see-more-header {
            border-color: var(--color-brand-100);
            background-color: var(--color-brand-100);
            color: var(--standard-text-color);
          }
        }
        &__label {
          font-size: 14px;
          @media (--mobile) {
            font-size: 12px;
          }
        }
      }
    }
    &__product-name {
      font-size: 40px;
      line-height: 50px;
      @media (--tablet-lg) {
        font-size: 38px;
        line-height: 48px;
      }
      @media (--tablet-sm) {
        font-size: 35px;
        line-height: 45px;
      }

      @media (--mobile) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

  .product-card__maininfo--not-for-sale .product-card__not-for-sale {
    background-color: $colorBorder1;
    border-radius: 0 0 4px 4px;
  }
}
.product-card {
  &__thumbnail {
    background-color: $color5;
    border-radius: 4px;
    &:hover {
      border-color: $color2;
    }
    &__active {
      box-shadow: none;
      &:hover {
        border-color: $color1;
      }
    }
    svg {
      fill: $color3;
    }
  }

  &__product-name,
  &__current-price,
  &__content__header__h2 {
    color: $color3;
  }
  &__content__header__h3 {
    color: $color3;
    opacity: 0.8;
    font-weight: 100;
    line-height: normal;
  }
  &__short-item-text:before {
    color: $color2;
  }
  &__short-item-text li:before {
    color: $color3;
  }
  &__short-item-text a {
    color: none;
    &:hover {
      color: none;
    }
  }

  &__tag__link {
    padding: 0;
    &--arrow {
      border-color: var(--brand-color);
    }
  }
  &__part-of-bundle__tag,
  &__volume-discount__tag {
    background-color: var(--brand-color);
    border-color: var(--brand-color);
    color: var(--color-on-brand-color);
    height: auto;
  }
  &__before-price {
    font-weight: 100;
    color: $color3;
  }

  &__stock {
    font-family: $fontFamilyArial;
  }

  &__variants {
    text-transform: none;
    &__savings {
      background-color: $color2;
      color: $color3;
    }
    &__image-variant {
      border-radius: 4px;
    }
    &__image-variant__active {
      box-shadow: 0 0 0 1px $color2;
    }
  }
  &__CTA {
    &__add-to-basket {
      font-weight: 400;
      border-radius: 4px;
      @media (--desktop) {
        font-size: 24px;
      }
      @media (--tablet-lg) {
        font-size: 24px;
      }
      @media (--tablet-sm) {
        font-size: 22px;
      }
      @media (--mobile) {
        font-size: 18px;
        line-height: 24px;
        .cart {
          display: none;
        }
      }
      @media (--mobile-lg) {
        font-size: 20px;
        line-height: 26px;
        .cart {
          display: block;
        }
      }
      &:hover {
        background-color: darken($color1, 10%);
      }
    }
  }
  &__part-of-bundle,
  &__volume-discount {
    border-radius: 4px;
    border-left: none;
    border-right: none;
    border-top: none;
  }
  &__product-image__energy-label span {
    font-weight: 400;
  }
  &__product-image__content__wrapper {
    background-color: $color5;
    border-radius: 4px;
    overflow: hidden;
  }
  &__content {
    @media (--mobile) {
      border-color: $color5;
    }

    &__wrapper,
    &__accessories,
    &__product-serie,
    &__others-are-looking-at,
    &__iu {
      background-color: $colorBorder1;
      @media (--tablet-and-above) {
        border-bottom: var(--border-thin) $color6;
      }
    }
    &__wrapper {
      background-color: $color5;
      border: none;
    }
  }


  &__part-of-bundle,
  &__splash,
  &__volume-discount {
    border-color: $colorBorder1;
  }
}
