.landingpage {
  .productlist--view-more .btn-brand:hover {
    color: $color5;
    background-color: $color8;
  }
}

.cms-halfwidth {
  .half-width__wrapper {
    &__style1 {
      .btn-brand {
        &:hover,
        &:active,
        &:focus {
          border-color: $color5;
          color: $color3;
        }
      }
    }
    &__style2 {
      .btn-whiteBorder {
        background-color: transparent;
        border-color: $color3;
        color: $color3;
        &:hover,
        &:active,
        &:focus {
          background-color: $color3;
          border-color: $color3;
          color: $color2;
        }
      }
    }
    &__style3 {
      .btn-brand {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        color: var(--color-on-secondary-color);
        &:hover,
        &:active,
        &:focus {
          background-color: var(--hover-of-primary-color);
          border-color: var(--hover-of-primary-color);
          color: var(--color-on-secondary-color);
        }
      }
    }
    &__style-standard {
      .btn-brand {
        &:hover,
        &:active,
        &:focus {
          background-color: $color8;
        }
      }
    }
    &__style-standard.is-reversed {
      .btn-brand {
        background: transparent;
        border-color: $color3;
        color: $color3;
        &:hover,
        &:active,
        &:focus {
          background-color: $color3;
          color: $color5;
        }
      }
    }
  }
}
