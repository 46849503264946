.landingpage {
  &__topbanner,
  &__videotopbanner {

    &__caption {
      h1,
      .heading--1 {
        font-weight: 200;
      }
      p {
        font-weight: 200;
      }
    }

    &__button {
      margin: 0;
      text-align: left;
    }
    &__nav {
      background-color: rgba(66, 66, 66, 0.7);
      &:hover {
        background-color: rgba(66, 66, 66, 0.9);
      }
   
    }


  }
}

.cms-section_carrousel,
.cms-topbanner, .cms-videotopbanner {
  border-bottom: 15px solid var(--brand-color);
  border-radius: 0;
  @media (--tablet-sm) {
    border-width: 10px;
  }
  @media (--mobile) {
    border-width: 7px;
  }
}

