// Colors
$color1: #5b8687; // alternativ: #1d8181;
$color2: #c8bb73; //Gold
$color3: #424242; //Black
$color4: #8d865e; //LightGreen
$color5: #ffffff; //White
$color6: #f4f1e3; //third color
$color7: #787979; //hover black
$color8: #466869; //secondary main color
$color9: #857d62; //other gold color
$color10: #2aa172; //secondary hover of main color
$color11: #424242; // black

// Backgrounds
$gColorbg1: #424242;
$colorBg1: #eaeaea; // Lightgrey - Body color - Primary grey
$colorBg2: #ffffff; // White - Product bg
$colorBg3: #ebebeb; // Grey - aside filters
$colorBg4: #ffe787; //offer yellow
$colorBg5: #7ea832; //btn hover gradient
$colorBg6: #6bb644; // green
$colorBg7: #424242; // black
$colorBg8: #6bb644; // green
$colorBg9: #a3d404; // lighter green
$colorBg10: #e1e1e1; // Secondary grey
$colorBg11: #dedede; // Tertiary grey

// Borders
$colorBorder1: #e6e2c2; // Gold light
$colorBorder2: #d9d9d9; // Dark(er) grey
$colorBorder3: #c6c6c6; // Dark grey (search inupt)
$colorBorder4: #2e87ab; // Lightblue
$colorBorder5: #424242; // Darkest
$colorBorder6: #424242; // Darkest
$colorBorder11: #aeead2; // Very light green

$borderThin: 1px solid;
$borderMedium: 2px solid;
$borderThick: 3px solid;

// Font
$colorFont1: #424242; // primary - grey
$colorFont2: #424242; // blackish
$colorFont3: #ffffff; // White (productlist btn)
$colorFont4: #666666; // lightgrey - footer usp  hover on links

$fontFamilyOswald: "Urbanist", sans-serif;
$fontFamilyArial: "Urbanist", sans-serif;
$gFontFamilyArial: "Urbanist", sans-serif;
