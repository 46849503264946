.landingpage .footer-container {
  background-color: transparent;
}

.footer {
  &__logo {
    @media (--tablet-and-above) {
      margin: 0 15px 0 0;
    }
    &-img {
      height: 25px;
      width: 153px;
      .shop-ljusochlampor-se & {
        width: 200px;
        height: 32px
      }
      svg {
        fill: $color3;
      }


    }
  }
  &__top,
  &__usp,
  &__mid {
    background-color: $color3;
  }
  &__contact__link,
  &__contact__link a,
  &__contact a,
  &__contact__block,
  &__contact__block a {
    &:hover {
      .footer__contact--icon {
        background-color: var(--brand-color);
        border-color:  var(--brand-color);
        svg {
         .icon-stroke {
            stroke: var(--color-on-brand-color);
          }
        }
      }
    }
  }
  &__contact--icon {
    border-color: #666;
    border-radius: 4px;
    svg {
      stroke-width: 0;
    }
  }
  .contact-block__title {
    text-transform: none;
    font-weight: 400;
  }
  .container {
    border-color: $colorFont4;
  }

  &__bot {
    background-color: var(--primary-color);
    border-color: #666;
    &--shopinfo span,
    &--shopinfo span a {
      color: var(--color-on-primary-color);
      border-color: var(--color-on-primary-color);
    }
  }
  &__usp a,
  &__usp .element,
  .heading--news-letter {
    text-transform: none;
    color: $color5;
    font-weight: 100;
  }
  &__usp {
    color: $color5;
    font-weight: 100;
 
    &__item {
      color: $color5;
      background-color: transparent;
      border: none;
      font-weight: 100;
      svg {
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
  &__list-section {
    .heading {
      text-transform: initial;
    }
  }
  &__list {
    border-color: $colorFont4;
  }
  &__text {
    margin-bottom: 15px;
  }
  &__news-letter {
    border-color: $colorFont4;
    border-radius: 4px;
    .form-control {
      height: 32px;
      margin: 0 0 5px 0;
      border: none;
      font-size: 12px;
      border-radius: 4px;
    }
    .btn {
      background-color: $color1;
      margin-top: 10px;
      height: auto;
      font-size: 14px;
      border-radius: 4px;
      &:hover,
      &:focus,
      &:active {
        background-color: $color8;
      }
    }
  }
  a,
  a:hover,
  a:focus {
    font-weight: 100;
  }
}
