.article .a-col .a-block h1,
.article .a-col .a-block h2,
.article .a-col .a-block h3 {
  color: $color5;
}

.article .a-col .a-block {
  border-radius: 4px 4px 0 0;
}
.article {
  @media (--tablet-and-above) {
    background-color: $color6;
  }
  .article-list .article-list-item .a-col {
    background-color: $color9;
    border-radius: 4px;
  }
  .article-list .article-list-item {
    p {
      color: $color5;
    }
  }
  .cms-article_social_profile {
    border-color: $color6;
  }
  .read-more {
    color: $color5;
  }
  .read-more-arrow {
    background-color: $color2;
    border-color: $color2;
    padding: 0;
    border-radius: 50px;
    svg {
      fill: $color3;
    }
  }
  .article-list .article-list-item:hover .read-more-arrow {
    background-color: $color1;
    border-color: $color1;
    svg {
      fill: $color5;
    }
  }
  .main-container {
    @media (--tablet-and-above) {
      background-color: $color6;
    }
  }
  .social-follow__article {
    border-color: $color5;
    border-radius: 4px;
    svg {
      fill: $color3;
    }
    &:hover {
      background-color: $color2;
      border-color: $color2;
      svg {
        fill: $color3;
      }
    }
  }
  &__campaign-period {
    > * {
      background-color: $color1;
      color: $color5;
      text-transform: none;

      @media (--tablet-and-above) {
        border-radius: 4px 4px 0 0;
      }
    }
    a:hover,
    a:focus {
      background-color: darken($color1, 10%);
    }
  }
  &__introduction h1 {
    color: $color2;
    font-size: 70px;
    font-weight: 400;
    line-height: 80px;
    @media (--tablet-lg) {
      font-size: 60px;
      line-height: 70px;
    }
    @media (--tablet-sm) {
      font-size: 50px;
      line-height: 60px;
    }
    @media (--mobile) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (--mobile-sm) {
      font-size: 35px;
      line-height: 45px;
    }
  }
  &__ingress {
    font-weight: 300;
    letter-spacing: 0;
    word-spacing: 0;
  }

  &__author img {
    border-radius: 4px;
  }
  &__blockquote {
    background-color: $color2;
    border-radius: 4px;
    svg {
      fill: $color3;
    }
  }

  &__guide,
  &__factbox {
    background-color: $color2;
    border-radius: 4px;
    color: $color3;
    .heading,
    .heading--1,
    .heading--2,
    .heading--3,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li,
    ul,
    ul li,
    ol,
    p,
    a,
    a:hover,
    a:focus {
      color: $color3;
    }
    ul li::before {
      color: $color3;
    }
    svg {
      fill: $color3;
    }
    .icon-factbox,
    .icon-guide {
      background-color: $color5;
      border-radius: 4px;
      display: none;
      svg {
        fill: $color3;
      }
    }
    .icon-factbox {
      padding-top: 4px;
    }
    h3,
    h4 {
      margin-left: 0;
    }
  }
  &__step-by-step li {
    background-color: transparent;
    border-bottom: var(--border-thin) $gColorBorder1;
    border-radius: 0;
    font-weight: 700;
    padding: 10px 15px 15px;
    @media (--mobile) {
      padding: 5px 0 10px;
    }
    &:last-of-type {
      border: none;
    }
  }
  &__step-by-step li::before {
    background-color: $color2;
    border-radius: 4px;
    color: $color3;
  }
  &__offer-banner {
    background-color: $color3;
    text-align: left;
    h2,
    h3,
    p {
      color: $color5;
      text-align: left;
    }
    h2 {
      font-weight: 700;
    }
    h2,
    h3 {
      @media (--mobile) {
        width: calc(100% - 75px);
      }
      @media (--mobile-sm) {
        width: calc(100% - 65px);
      }
    }

    .btn {
      background-color: $color1;
      border-color: $color1;
      color: $color5;

      &:hover {
        background-color: darken($color1, 10%);
        border: none;
        border-color: darken($color1, 10%);
        color: $color5;
        &:hover {
          background-color: $color10;
          border: none;
          color: $color5;
        }
      }
    }

    &__splash {
      display: none;
      fill: $color2;
      &__text {
        background-color: $color2;
        border-radius: 4px;
        color: $color3;
        font-size: 22px;
        height: 100px;
        text-transform: none;
        width: 100px;
        @media (--tablet-lg) {
          font-size: 21px;
          height: 95px;
          right: 15px;
          top: 15px;
          width: 95px;
        }
        @media (--tablet-sm) {
          font-size: 20px;
          height: 90px;
          right: 10px;
          top: 10px;
          width: 90px;
        }
        @media (--mobile) {
          display: flex;
          font-size: 18px;
          height: 70px;
          right: 10px;
          top: 10px;
          width: 70px;
        }
        @media (--mobile-sm) {
          font-size: 16px;
          height: 60px;
          width: 60px;
        }
      }
    }
    &__period {
      color: $color5;
      font-size: 0.7rem;
      opacity: 0.7;
    }
    .highlight__padding-container {
      padding: 60px 40px;
      text-align: left;
      @media (--tablet-lg) {
        padding: 40px;
      }
      @media (--tablet-sm) {
        padding: 30px;
      }
      @media (--mobile) {
        padding: 15px;
      }
    }
  }
}

.cms-faq {
  padding: 50px 0 60px;
}

.cms-faqblock {
  background-color: initial;
  .faq__icon {
    background-color: $color2;
    border-radius: 4px;
    color: $color3;
    padding-top: 2px;
    font-weight: 100;
    @media (--mobile) {
      border-radius: 0;
    }
  }
  .faq__line {
    border-color: $color2;
  }
  .faq__column li {
    border-radius: 4px;
    border: var(--border-thin) $gColorBorder1;
  }
}

.productlist-footer .article__image,
.productlist-footer .article__video,
.half-width__wrapper__style-standard .half-width__image,
.article__textbox,
.embed-responsive,
{
  border-radius: 4px;
}

.productlist-footer ul li::before {
  color: $color3;
}
.article__textbox {
  background-color: $color6;
}

.article__textbox.bgcolor {
  background-color: $color3;
}

.article__highligted-text h1,
.article__highligted-text h2,
.article__highligted-text h3,
.article__highligted-text h4,
.article__highligted-text p {
  background-color: $color2;
  color: $color3;
}

.highlight__checkmark {
  background-color: var(--dark);

}

.article__step-by-step,
.points {
  border: none;
}

.cms-subpages {
  .heading--2,
  h2 {
    color: $color5;
  }
  .article {
    @media (--tablet-and-above) {
      background-color: transparent;
    }
  }
}

.article figure {
  background-color: transparent;
}
.article .article-list .article-list-item:hover .a-col {
  box-shadow: none;
}
