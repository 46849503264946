.landingpage {
  &__campaign-banner,
  &__campaign-banner.a {
    text-align: center;
    height: auto;
    font-family: var(--button-font);
    font-weight: var(--font-weight-bold);
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    padding: 6px 16px;
    text-decoration: none;
    transition: var(--standard-transition-all);
    letter-spacing: var(--letter-spacing-xs);
    word-spacing: var(--word-spacing-xs);
    background-color: var(--highligted-background-color);
    color: var(--color-on-highligted-background-color);
    .design-completvvs &, .design-greenline & {
      background-color: var(--brand-color);
    }
    .design-lavpris & {
    font-size: calc(14 * 1.4);
    }
    @media (--mobile) {
      letter-spacing: initial;
      word-spacing: initial;
      font-size: 12px;
      line-height: 16px;
      padding: 6px 16px;
      .design-lavpris & {
        font-size: calc(12 * 1.4);
        }
    }

    &:hover,
    &:active,
    &:focus,
    &:focus:hover {
      text-decoration: none;
      transition: var(--standard-transition-all);
      background-color: var(--hover-of-highligted-background-color);
      color: var(--color-on-highligted-background-color);



      .design-greenline & {
        color: var(--color-on-cta-color);
        background-color: var(--secondary-color);

      }
    }

    &__info {
      display: flex !important;
      justify-content: space-between;
    }

    &__text {
      display: inline-block;
      text-align: center;
    }

    &__divider {
      background-color: $color11;
      width: 2px;
      display: inline-block;
      margin: 0 20px;
      padding: 5px 0;
      @media (--tablet) {
        padding: 3px 0;
      }
      @media (--mobile) {
        display: none;
      }
    }

    &__arrow {
      display: flex;
      margin: 0 0 0 8px;
      background-color: transparent;


      svg {
        height: 14px;
        width: 14px;
        transform: rotate(90deg);

        .design-lavpris & {
          display: none;
        }
        
.icon-stroke {
      stroke:  var(--color-on-highligted-background-color);
      stroke-width: 2;
    }
        @media (--mobile) {
display: none;
        }
      }
    }
  }
}
