.productlist {
  &--view-more {
    justify-content: flex-start;
    .btn {
      margin: 0;
      text-align: left;
    }
  }
}


.productlist__focus-banner {
  .blockbanner {
    border-radius: 0;
  }
}

.cms-partnerlist {
  background-color: $colorBorder1;
  .productbox__slider {
    background-color: $color5;
  }
}
