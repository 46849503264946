.aa-dataset-products,
.aa-datasets-not-products {
  background-color: var(--primary-background-color);
}

.aa-suggestion {
  border-color: $colorBorder1;
  &:hover {
    border-color: $color2;
  }
}

.aa-datasets-not-products .aa-dataset-brands .aa-suggestion > a,
.aa-datasets-not-products .aa-dataset-groups .aa-suggestion > a {
  border-color: $colorBorder1;
  font-weight: 400;
  @media (--tablet-and-above) {
    font-weight: 0.8em;
  }
  &:hover {
    border-color: $color2;
  }
}

.aa-datasets-not-products .aa-dataset-inspiration .aa-suggestion > a {
  border-color: $colorBorder1;
  &:hover {
    border-color: $color2;
  }
}

.aa-suggestion > a.algolia-autocomplete__precise-match,
.aa-suggestion,
.aa-datasets,
.aa-datasets-not-products .aa-dataset-inspiration .aa-suggestion > a {
  border-radius: var(--rounded-corners);
}

.aa-datasets-not-products .aa-dataset-brands,
.aa-datasets-not-products .aa-dataset-groups,
.aa-datasets-not-products {
  border-color: $color6;
  background-color: var(--tertiary-background-color);
}

.aa-datasets-not-products .aa-dataset-inspiration .aa-suggestion > a .algolia-autocomplete__description__read-more {
  color: $color3;
  font-size: 11px;
  text-transform: initial;
  svg {
    background-color: $color1;
    fill: $color5;
  }
}